import { ui, select } from '@owenscorning/pcb.alpha';

import Product from './Product';
import parocData from './data/paroc';
import { makeT } from "../../../../location/locales";
import { wrapRef } from '../../../../../data';
import { anchor as stickyNavAnchor } from "../../../../helpers/sticky_nav";

const onlyModules = [
  {
    type: 'TabbedContent',
    modules: [
      'BasicContent',
      'ContentAndImage',
      'FullBleedContent',
      'Gallery',
      'MediaObjectSet',
      'Reusable',
      'Table',
    ],
  },
];

const Paroc = Product(
  'Product::Paroc',
  ui`AttributeSet`({
    query: {
      name: 'Product Attributes',
      path: '/',
    },
    linked_object: select`~linked_object`
  }),
  {},
  {},
  {
    version: '0.1',
    displayName: 'Paroc PDP',
    paths: ['products'],
    allowsPIMLink: true
  },
  (build) => [
    {
      title: 'Technical Information',
      modules: ['Table', 'CardSet', 'BasicContent'],
      opts: { onlyModules },
      initial: [
        {
          type: 'TabbedContent',
          data: parocData(build.language).technicalInformation,
        },
      ],
      showHeading: true,
      translationKey: "product.paroc.technical_information",
    },
    {
      title: 'Certifications',
      modules: ['BasicContent'],
      initial: [
        {
          type: 'CardSet',
          data: parocData(build.language).certifications,
        },
      ],
      showHeading: true,
      translationKey: "product.paroc.certifications",
    },
    {
      title: 'Promotions',
      modules: ['BasicContent'],
      initial: ['FullBleedContent', 'Reusable'],
      showHeading: true,
      translationKey: "product.paroc.promotions",
    },
    {
      title: 'Specifications & Literature',
      unmovables: [true],
      modules: ['BasicContent', 'ItemList', 'Reusable'],
      initial: [
        'SpecificationsAndLiterature',
        {
          type: 'Reusable',
          data: {
            reference: wrapRef('Cms::Content', {
              type: 'Reusable',
              path: '',
              name: 'Certificates Content Block',
            }),
          },
        },
      ],
      showHeading: true,
      translationKey: "product.paroc.specifications_and_literature",
    },
    {
      title: 'Solutions & Related Products',
      modules: ['BlockLinkSet', 'BasicContent', 'ContentAndImage', 'CardSet'],
      opts: { onlyModules },
      initial: [
        {
          type: 'TabbedContent',
          data: parocData(build.language).solutionsAndRelatedProducts,
        },
      ],
      showHeading: true,
      translationKey: "product.paroc.solutions_and_related_products",
    },
  ],
  null,
  (build) => {
    const t = makeT(build?.language || 'en-US');
    return {
      layout: {
        Header: {
          StickyNav: {
            cta: {
                url: `${stickyNavAnchor(t("product.paroc.specifications_and_literature"))}`,
                text: t('product.paroc.download_documents'),
                color: 'standard',
                destination: 'link',
                modalSource: 'form',
                linkType: 'anchor',
            },
          },
        },
      },
      metadata: {
        settings: {
          general: {
            body_copy: {
              ops: [
                {
                  insert:
                    'Marketing body lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere nunc. Pellentesque a luctus turpis. In id semper mauris, ut varius odio. Sed ac erat ut orci finibus vehicula sit amet ac diam. In finibus dolor massa, non semper dolor dignissim vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sodales magna justo, sed iaculis ante feugiat cursus. Ut blandit sodales orci, venenatis bibendum metus feugiat at. Ut tempus blandit aliquam.\nUpdated 10 Jan 2024',
                },
                {
                  insert: {
                    breaker: true,
                  },
                },
                {
                  insert: '\n',
                },
              ],
            },
            subheading: 'Optional Marketing Subhead',
              proper_name: 'Product Name',
              long_description:
            'Long description lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere.',
              short_description:
            'Short description lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam enim eros, dignissim eget auctor eu, tincidunt ut mi. Ut blandit odio et lectus finibus ullamcorper. Etiam luctus elit pulvinar, feugiat ante fringilla, posuere.',
          },
          images: {
            images: [
              {
                file:
                  'https://imagecdn.owenscorning.com/ocimages/image/upload/v1/pagebuilder/uploads/dev/b0673964-883f-42cc-9471-9db5dc9669de/Placeholder-Product-Image-hero',
              },
            ],
              hero_image: 0,
              list_item_image: 0,
          },
        },
      },
    }
  }
);

export default Paroc;
