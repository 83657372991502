import { BadgesProvider } from './badges';
import { openProfessionalEmailModal } from './insulation-professionals/email-professional-modal';
import { openEmailContractorModal } from './insulation-professionals/open-email-contractor-modal';
import { getRoofingInsulationLogo } from './logos';

const WEARDECK = {
  uid: 'composite-lumber',
  resultComponentOptions: {},
};

const ROOFING_SUPPLIERS = {
  uid: 'roofing-suppliers',
  resultComponentOptions: {
    attributes: {
      logo: (_attrs, data) => getRoofingInsulationLogo(data),
    },
  },
};

const INSULATION_SUPPLIERS = {
  uid: 'insulation-residential-suppliers',
  resultComponentOptions: {
    attributes: {
      logo: (_attrs, data) => getRoofingInsulationLogo(data),
    },
  },
};

const ROOFING_CA_DISTRIBUTORS = {
  uid: 'roofing-ca-distributors',
  resultComponentOptions: {},
};

const PAROC_SE_DEALERS = {
  uid: 'paroc-se-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_LV_DEALERS = {
  uid: 'paroc-lv-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_DK_DEALERS = {
  uid: 'paroc-dk-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_FI_DEALERS = {
  uid: 'paroc-fi-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_LT_DEALERS = {
  uid: 'paroc-lt-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_EE_DEALERS = {
  uid: 'paroc-ee-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const PAROC_GB_DEALERS = {
  uid: 'paroc-gb-dealers',
  resultComponentOptions: {
    options: { showCTA: false, showDistance: false },
  },
};

const INSULATION_FIND_A_PROFESSIONAL = {
  uid: 'insulation-professionals',
  wrapperComponent: BadgesProvider({
    path: 'insulation',
    name: 'Find a Professional',
  }),
  resultComponentOptions: {
    options: {
      showDistance: false,
      showBadges: true,
    },
    attributes: {
      logo: (_attrs, data) => getRoofingInsulationLogo(data),
    },
    components: {
      CTA: {
        getProps: (attrs, data, _opts, t) => ({
          show: !!data.cee && !!data.company_email,
          onClick: () => openProfessionalEmailModal(data, attrs.name),
          label: t('generic_locator.email'),
          track: {
            name: 'form-start',
            contractorName: data.name,
            contractorId: data.membership_number,
            formName: 'email-insulation-contractor',
            professional: 'certified-energy-experts',
          },
        }),
      },
      Email: {
        getProps: (_attrs, data, _opts, t) => ({
          show: !data.cee && !!data.company_email,
          label: t('generic_locator.email'),
          email: data.company_email,
          name: data.name,
          onClick: () => openEmailContractorModal(data),
          track: {
            name: 'form-start',
            contractorName: data.name,
            contractorId: data.membership_number,
            formName: 'email-insulation-contractor',
            professional: 'other-independent-insulation-contractors',
          },
        }),
      },
    },
  },
};

const LOCATOR_ARRAY = [
  WEARDECK,
  ROOFING_SUPPLIERS,
  INSULATION_SUPPLIERS,
  ROOFING_CA_DISTRIBUTORS,
  PAROC_SE_DEALERS,
  PAROC_LV_DEALERS,
  PAROC_DK_DEALERS,
  PAROC_FI_DEALERS,
  PAROC_LT_DEALERS,
  PAROC_EE_DEALERS,
  PAROC_GB_DEALERS,
  INSULATION_FIND_A_PROFESSIONAL,
];

export const LOCATORS = Object.fromEntries(
  LOCATOR_ARRAY.map(locator => [locator.uid, locator])
);

export const locatorOptions = Object.fromEntries(
  LOCATOR_ARRAY.map(({ uid }) => [uid, uid])
);
