import { Component, useState } from 'react';
import Comps from '@owenscorning/react-library';
import StateSelect from './select_field';
import { jsx, css } from '@emotion/react';
import Text from '../../../OC/oc-text';
import LabelWrapper from '../../LabelWrapper';
import RichText from '../../../OC/oc-rich-text';
import _ from 'lodash';

const addressField = css`
input, select {
  margin-bottom: 3px;
}

.twoFieldsRow {
  display: flex;
  flex-direction: row;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  .fieldItem {
    width: calc(50% - 10px);

    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.verification-result {
  padding-top: 10px;

  h6 {
    color: #333;
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 0;
  }

  input[type="radio"] {
    margin-right: 6px;
  }

  .entered-address {
    float: left;
    margin-right: 50px;
  }

  .address-group {
    transform: translateX(22px);
  }
}
`

const errorAlert = css`
  color: #940420;
  font-size: 12px;
  font-weight: bold;
`

const Spinner = Comps.Connect.Spinner;
const suggestionEndpoint = 'https://mdms.owenscorning.com/api/v1/smarty/suggestions';
const verifyEndpoint = 'https://mdms-devel.owenscorning.com/forms/verify-address';


const States = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC',
  'ND', 'MP', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX',
  'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
].sort().map((state, index) => ({ id: index + 1, label: state}));

const minQueryLength = 6;
const debounceTime = 550;

const addressObjectToString = (address) => {
  return `${address.street_line}, ${address.city}, ${address.state}, ${address.zipcode}`
}

const BLANK_ADDRESS = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
}

const renderTimeout = (query, fetchFxn) => {
  return setTimeout(function () {
    fetchFxn(query)
  }, debounceTime)
}

const checkSuggestionsForMatch = (str, suggestions) => {
  return _.find(suggestions, (suggestion) => addressObjectToString(suggestion) == str)
}

const checkPOBox = (string) => {
  return /PO Box|po box|p\.o\. box|P\.O\. Box/i.test(string);
}

export default function UsSmartyAddressField({formData = {}, onChange, schema, uiSchema, errorSchema}) {
  const defaultAddress = _.merge({}, BLANK_ADDRESS, schema.default);
  const shouldVerify = uiSchema['ui:options']?.verify || false;
  const [addressID, setAddressID] = useState(_.uniqueId('streetAddress-'))
  const [suggestions, setSuggestions] = useState([])
  const [fetching, setFetching] = useState(false)
  const [suggestedAddress, setSuggestedAddress] = useState(defaultAddress)
  const [localAddress, setLocalAddress] = useState(defaultAddress)
  const [noMatchFound, setNoMatchFound] = useState(false)
  const [perfectMatch, setPerfectMatch] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [timeoutID, setTimeoutID] = useState(null)
  const [poboxWarning, setPoboxWarning] = useState(false)


  const verifyAddress = () => {
    if (!verifying) {
      setVerifying(true)
      setNoMatchFound(false)
      setPerfectMatch(false)
      fetch(verifyEndpoint, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          street_1: suggestedAddress.addressLine1,
          street_2: suggestedAddress.addressLine2,
          city: suggestedAddress.city,
          state: suggestedAddress.state,
          zip: suggestedAddress.zip
        })
      })
        .then(response => response.json())
        .then(json => {
          if (json.error) {
            onChange({
              ...localAddress,
              addressLine1: localAddress.addressLine1 || formData?.addressLine1
            })
            setVerifying(false)
          } else if (json.verified_result === null) {
            onChange({
              ...localAddress,
              addressLine1: localAddress.addressLine1 || formData?.addressLine1
            })
            setNoMatchFound(true)
          } else if (
            json.provided_address.street_1 === json.verified_result.street_1 &&
            json.provided_address.street_2 === json.verified_result.street_2 &&
            json.provided_address.city === json.verified_result.city &&
            json.provided_address.state === json.verified_result.state &&
            json.provided_address.zip === json.verified_result.zip
          ) {
            setPerfectMatch(true)
            onChange({
              ...localAddress,
              addressLine1: localAddress.addressLine1 || formData?.addressLine1
            })
          } else {
            setSuggestedAddress({
              addressLine1: json.verified_result.street_1,
              addressLine2: json.verified_result.street_2,
              city: json.verified_result.city,
              state: json.verified_result.state,
              zip: json.verified_result.zip,
            })
            onChange({...suggestedAddress})
          }
        })
    }
  }

  const fetchSuggestion = (query) => {
    setFetching(true)
    fetch(`${suggestionEndpoint}?street_1=${query}&country_code=${'US'}`, {
      method: 'get'
    })
      .then(response => response.json())
      .then(jsonData => {
        setSuggestions(jsonData)
        if(jsonData.length === 0) {
          setLocalAddress({...localAddress, addressLine1: query})
        }
      })
      .catch(err => {
        // TODO handle error?
        setSuggestions([])
        console.log(err);
      })
      .finally(() => {
        setFetching(false)
      })
  }

  const addressObjectToFields = (address) => {
    setLocalAddress({
      ...localAddress,
      addressLine1: address.street_line,
      city: address.city,
      state: address.state,
      zip: address.zipcode,
    });
  }

  const clickSuggestion = (suggestion) => {
    setSuggestions([])
    addressObjectToFields(suggestion);
  }

  const onQueryTextChange = (query) => {
    clearTimeout(timeoutID);

    let clicked = checkSuggestionsForMatch(query, suggestions)
    if (clicked) {
      clickSuggestion(clicked);
      return
    }

    if (query.length >= minQueryLength) {
      setTimeoutID(renderTimeout(query, () => fetchSuggestion(query)))
    }

    addressObjectToFields(query)
  }

  const ErrorDisplay = ({field, schemaProp, errorSchema}) =>
  ((schema.required || []).includes(field) && errorSchema) ?
  <p aria-describedby={field} aria-errormessage css={errorAlert} role="alert">
    {schemaProp.title?.trim?.()} is required
  </p> : null

  return (
    <div css={addressField}>
      <fieldset className="address-field" onBlur={verifyAddress} >
        <div className="form-group field field-object">
          <LabelWrapper htmlFor="addressLine1">
            <Text content={schema.properties.addressLine1.title?.trim?.()} />{(schema.required || []).includes('addressLine1') ? <span title="Required">*</span> : null}
          </LabelWrapper>
          <input name="addressLine1"
            id="addressLine1"
            className="form-control"
            type="text"
            onChange={(e) => {
              setLocalAddress({...localAddress, addressLine1: e.target.value})
              onQueryTextChange(e.target.value)
              setPoboxWarning(checkPOBox(e.target.value))
              onChange({...formData, addressLine1: e.target.value})
            }}
            value={localAddress.addressLine1}
            list={addressID}
            required={(schema.required || []).includes('addressLine1')}
          />
          {fetching && <Spinner />}
          <datalist id={addressID}>
            {suggestions.map((suggestion, i) =>
              <option key={i}>
                {addressObjectToString(suggestion)}
              </option>
            )}
          </datalist>

          {(!poboxWarning && uiSchema?.addressLine1?.['ui:help']) && <RichText content={uiSchema?.addressLine1?.['ui:help']} singular />}
          {(uiSchema?.addressLine1?.['ui:help'] && poboxWarning && uiSchema?.addressLine1?.['ui:error']) && <div css={errorAlert}><RichText content={uiSchema?.addressLine1?.['ui:error']} singular /></div>}
          {/* We want to leave it as is, if no ui:error is provided on mdms or po box warning is not a concern */}
          {(uiSchema?.addressLine1?.['ui:help'] && poboxWarning && !uiSchema?.addressLine1?.['ui:error']) && <RichText content={uiSchema?.addressLine1?.['ui:help']} singular />}

         <ErrorDisplay field='addressLine1' schemaProp={schema.properties.addressLine1} errorSchema={errorSchema?.addressLine1} />
        </div>
        {schema.properties?.addressLine2 &&
          <div className="form-group field field-object">
            <LabelWrapper htmlFor="addressLine2">
              <Text content={schema.properties.addressLine2.title?.trim?.()} />{(schema.required || []).includes('addressLine2') ? <span title="Required">*</span> : null}
            </LabelWrapper>
            <input name="addressLine2"
              id="addressLine2"
              className="form-control"
              type="text"
              value={localAddress.addressLine2}
              onChange={(e) => {
                setLocalAddress({...localAddress, addressLine2: e.target.value})
                onChange({...formData, addressLine2: e.target.value})
              }}
              required={(schema.required || []).includes('addressLine2')}
            />
            {uiSchema?.addressLine2?.['ui:help'] &&
              <RichText content={uiSchema?.addressLine2?.['ui:help']} singular />}
          </div>
        }
        <div className="form-group field field-object">
          <div className="twoFieldsRow">
            <div className='fieldItem' style={{ marginRight: '20px' }}>
              <LabelWrapper htmlFor="city">
                <Text content={schema.properties.city.title?.trim?.()} />{(schema.required || []).includes('city') ? <span title="Required">*</span> : null}
              </LabelWrapper>
              <input name="city"
                id="city"
                className="form-control"
                type="text"
                value={localAddress.city}
                onChange={(e) => {
                  setLocalAddress({...localAddress, city: e.target.value})
                  onChange({...formData, city: e.target.value})
                }}
                required={(schema.required || []).includes('city')}
              />
              {uiSchema?.city?.['ui:help'] && <RichText content={uiSchema?.city?.['ui:help']} singular />}

              <ErrorDisplay field='city' schemaProp={schema.properties.city} errorSchema={errorSchema?.city} />
            </div>
            <div className='fieldItem'>
              <LabelWrapper htmlFor="state">
                <Text content={schema.properties.state.title?.trim?.()} />{(schema.required || []).includes('state') ? <span title="Required">*</span> : null}
              </LabelWrapper>
              <StateSelect id="state"
                showLabel={false}
                name="state"
                options={States}
                handleChange={ (e) => {
                  setLocalAddress({...localAddress, state: e.target.value})
                  onChange({...formData, state: e.target.value})
                }}
                value={localAddress.state}
                required={(schema.required || []).includes('state')} />
              {uiSchema?.state?.['ui:help'] && <RichText content={uiSchema?.state?.['ui:help']} singular />}

              <ErrorDisplay field='state' schemaProp={schema.properties.state} errorSchema={errorSchema?.state} />
            </div>
          </div>
        </div>
        <div className="twoFieldsRow">
          <span className="zip-code fieldItem" style={{ marginRight: '20px' }}>
            <LabelWrapper htmlFor="zip">
              <Text content={schema.properties.zip.title?.trim?.()} />{(schema.required || []).includes('zip') ? <span title="Required">*</span> : null}
            </LabelWrapper>
            <input name="zip"
              id="zip"
              className="form-control"
              type="text"
              onChange={(e) => {
                setLocalAddress({...localAddress, zip: e.target.value})
                onChange({...formData, zip: e.target.value})
              }}
              value={localAddress.zip}
              required={(schema.required || []).includes('zip')}
            />
            {uiSchema?.zip?.['ui:help'] && <RichText content={uiSchema?.zip?.['ui:help']} singular />}
            <ErrorDisplay field='zip' schemaProp={schema.properties.zip} errorSchema={errorSchema?.zip} />
          </span>
        </div>
      </fieldset>
      <div className="verification-result" hidden={!shouldVerify}>
        <div hidden={perfectMatch || suggestedAddress.addressLine1 === ''} role="alert">
          <h6>Please Verify Address</h6>
          <p>
            We could not verify the address entered above, but found a close match.
            Please verify the address you would like to use, or edit your entry.
          </p>
          <div className="entered-address">
            <label>
              <input type="radio" value="entered" name="address" checked />
              Entered Address:
            </label>
            <p className="address-group">
              {formData.addressLine1}<br />
              {formData?.addressLine2}{formData?.addressLine2 && <br />}
              {formData.city}, {formData.state} {formData.zip}
            </p>
          </div>
          <div className="suggested-address">
            <label>
              <input type="radio" value="suggested" name="address" onChange={() => onChange({...suggestedAddress})} />
              Suggested Address:
            </label>
            <p className="address-group">
              {suggestedAddress.addressLine1}<br />
              {suggestedAddress.addressLine2}{suggestedAddress.addressLine2 && <br />}
              {suggestedAddress.city}, {suggestedAddress.state} {suggestedAddress.zip}
            </p>
          </div>
          <div className="float-clear" />
        </div>
        <p hidden={!noMatchFound} role="alert">
          Please double-check your entry. We could not verify the address entered above.
          (You will still be able to submit this form.)
        </p>
      </div>
    </div>
  );
}
