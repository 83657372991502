/* eslint-disable no-param-reassign */
function transformErrorsInner({ errors, schema, uiSchema, t }) {
  // note: custom validation errors are appended AFTER this function runs, so we won't see them here.
  return errors.map(error => {
    switch (error.name) {
      case 'pattern':
        error.message = schema?.messages?.pattern || t('errors.forms.pattern');
        break;
      case 'format':
        error.message =
          schema?.messages?.format ||
          t('errors.forms.format', { format: error?.params?.format });
        break;
      case 'maxLength':
        error.message =
          schema?.messages?.maxLength || t('errors.forms.max_length');
        break;
      default:
        error.message =
          schema?.messages?.required || t('errors.forms.is_required');
        break;
    }

    return error;
  });
}

// RJSF only passes errors and uiSchema, so we'll have to closure t and schema into a callback.
export function makeErrorTransformer(t, schema) {
  return function transformErrors(errors, uiSchema) {
    return transformErrorsInner({ errors, schema, uiSchema, t });
  };
}
