/* eslint-disable no-restricted-syntax, no-param-reassign */
import _ from 'lodash';

import flattenKeys from '../../helpers/flatteKeys';

// "confirms" i.e. re-enter to confirm
// USAGE: define a "confirms" field with ui:options.confirms = $path
// where $path is the path for the property which is being confirmed
function validateConfirmationFields({ formData, errors, uiSchema, schema, t }) {
  // flatten the key paths
  // => 'path.to.field.ui:options.confirms'
  // pick the 'ui:options.confirms entries
  // drop the '.ui:options.confirms' suffix
  // => { 'path.to.field': 'path.to.confirm' }
  const confirmFields = _.mapKeys(
    _.pickBy(flattenKeys(uiSchema), (_v, k) =>
      k?.endsWith('ui:options.confirms')
    ),
    (_v, k) => k.replace('.ui:options.confirms', '')
  );
  for (const path of Object.keys(confirmFields)) {
    const expectingPath = confirmFields[path];
    const validatingValue = _.get(formData, path);
    const expectingValue = _.get(formData, expectingPath);
    if (validatingValue !== expectingValue) {
      _.get(errors, path)?.addError(
        t('errors.forms.match', { path: expectingPath })
      );
    }
  }

  return errors;
}

function customValidateInner({ formData, errors, uiSchema, schema, t }) {
  errors = validateConfirmationFields({
    formData,
    errors,
    uiSchema,
    schema,
    t,
  });
  // we can add more custom validations here, call addError on the errors tree
  return errors;
}

export function makeCustomValidate(t, schema) {
  return function customValidate(formData, errors, uiSchema) {
    return customValidateInner({ formData, errors, uiSchema, schema, t });
  };
}
